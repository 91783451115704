import React from "react";
import { useSelector } from "react-redux";
//
import {
  selectPersonData,
  selectRiskData,
  selectVehicleData
} from "redux/features/QuotationForm/quotationDataSlice";
import { selectTotalProducts } from 'redux/features/QuotationForm/quotationResultSlice'
import CustomHeader from 'components/CustomHeader/CustomHeader'

// core components
import GridContainer from "components/Grid/GridContainer.js";
import Accordion from "components/Accordion/Accordion.js";
import GridItem from "components/Grid/GridItem.js";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import styles from "assets/jss/material-kit-pro-react/views/ecommerceSections/latestOffersStyle.js";
import { formatDate } from "utils/functions";
import { selectThemeConfig } from "redux/features/Theme/ThemeSlice";
import { selectIsQuote } from "redux/features/QuotationForm/quotationDataSlice";
import { selectCompanyData } from "redux/features/QuotationForm/quotationDataSlice";
import { selectQuotationData } from "redux/features/QuotationForm/quotationDataSlice";

const useStyles = makeStyles(styles);

export default function SectionData() {

  const theme = useSelector(selectThemeConfig)

  const isQuote = useSelector(selectIsQuote)

  const {
    name,
    surname,
    phoneNumber,
    email,
    idType,
    idNumber,
    gender,
    birthdate,
  } = useSelector(selectPersonData);

  const companyData = useSelector(selectCompanyData)

  const {companyName, legRepPhoneNumber, legRepEmail} = companyData 
  const companyIdType = companyData.idType
  const companyIdNumber = companyData.idNumber

  const { code, plate, type, brand, model, codification } = useSelector(
    selectVehicleData
  );

  const {plateNumber} = useSelector(selectQuotationData)

  const { vehiclePrice, accesoriesPrice, placeData } = useSelector(
    selectRiskData
  );
  const products = useSelector(selectTotalProducts)

  const classes = useStyles();

  return (
    <div className={classes.section}>
      <div className={classes.container}>
        <Accordion
          active={-1}
          activeColor="info"
          collapses={[
            {
              title: `Seguro para ${name || companyName} ${surname || "N"}. ${idType || companyIdType}: ${idNumber || companyIdType}. Género: ${gender || "NA"}. Fecha de nacimiento: ${birthdate ? formatDate(
                new Date(birthdate)
              ) : "NA"}`,
              content: (
                <GridContainer>
                  <GridItem xs={12} sm={6} md={3}>
                    <span>
                      <b>Nombre(s): </b>
                      {name || companyName}
                    </span>
                  </GridItem>
                  <GridItem xs={12} sm={6} md={3}>
                    <span>
                      <b>Apellido(s): </b>
                      {surname || "NA"}
                    </span>
                  </GridItem>
                  <GridItem xs={12} sm={6} md={3}>
                    <span>
                      <b>Documento: </b>
                      {idType || companyIdType} - {idNumber || companyIdNumber}
                    </span>
                  </GridItem>
                  <GridItem xs={12} sm={6} md={3}>
                    <span>
                      <b>Email: </b>
                      {email || legRepEmail}
                    </span>
                  </GridItem>
                  <GridItem xs={12} sm={6} md={3}>
                    <span>
                      <b>Teléfono: </b>
                      {phoneNumber || legRepPhoneNumber}
                    </span>
                  </GridItem>
                </GridContainer>
              ),
            },
            {
              title: `Vehiculo ${type} ${brand} ${codification.line1} - Placa: ${plateNumber || plate.toUpperCase()}. Valor: ${vehiclePrice}. Fasecolda: ${code}`,
              content: (
                <GridContainer>
                  <GridItem xs={12} sm={6} md={3}>
                    <span>
                      <b>Marca: </b>
                      {brand}
                    </span>
                  </GridItem>
                  <GridItem xs={12} sm={6} md={3}>
                    <span>
                      <b>Referencia: </b>
                      {codification.line1}
                    </span>
                  </GridItem>
                  <GridItem xs={12} sm={6} md={3}>
                    <span>
                      <b>Modelo: </b>
                      {model}
                    </span>
                  </GridItem>
                  <GridItem xs={12} sm={6} md={3}>
                    <span>
                      <b>Tipo: </b>
                      {type}
                    </span>
                  </GridItem>
                  <GridItem xs={12} sm={6} md={3}>
                    <span>
                      <b>Placa: </b>
                      {plate.toUpperCase()}
                    </span>
                  </GridItem>
                  <GridItem xs={12} sm={6} md={3}>
                    <span>
                      <b>Ciudad de Circulación: </b>
                      {placeData}
                    </span>
                  </GridItem>
                  <GridItem xs={12} sm={6} md={3}>
                    <span>
                      <b>Código: </b>
                      {code}
                    </span>
                  </GridItem>
                  <GridItem xs={12} sm={6} md={3}>
                    <span>
                      <b>Valor Asegurable: </b> {vehiclePrice}
                    </span>
                  </GridItem>
                  <GridItem xs={12} sm={6} md={3}>
                    <span>
                      <b>Valor Accesorios: </b> {accesoriesPrice}
                    </span>
                  </GridItem>
                </GridContainer>
              ),
            },
          ]}
        />
      </div>
      <style jsx></style>
    </div>
  );
}