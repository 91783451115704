/*eslint-disable*/
import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import Parallax from "components/Parallax/CustomParallax.js";
import GridItem from "components/Grid/GridItem.js";
// sections for this page
import SectionProducts from "views/EcommercePage/Sections/SectionProducts.js";
import SectionData from "views/EcommercePage/Sections/SectionData.js";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// redux
import { selectTotalProducts } from "redux/features/QuotationForm/quotationResultSlice";
import { useSelector } from "react-redux";

import styles from "assets/jss/material-kit-pro-react/views/ecommerceStyle.js";
import CustomHeader from "components/CustomHeader/CustomHeader";
import {selectThemeConfig} from 'redux/features/Theme/ThemeSlice';

const useStyles = makeStyles(styles);

export default function EcommercePage({ ...rest }) {
  const products = useSelector(selectTotalProducts);
  const theme = useSelector(selectThemeConfig);

  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });

  const classes = useStyles();
  return (
    <React.Fragment>
      <div id="ECommercePage" className={classNames(classes.main,classes.container, classes.mainRaised)} style={{margin: "auto", marginTop: "1em", paddingLeft: "0", paddingRight: "0"}}>
        {theme.header ? <CustomHeader asesorInfo = {true} backButton={false}/> : null}
        <SectionData theme={theme}/>
        <SectionProducts theme={theme}/>
      </div>
    </React.Fragment>
      
  );
}
