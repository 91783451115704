import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Paper } from "@material-ui/core";
// @material-ui/icons
import CompareIcon from "@material-ui/icons/Compare";
// core components
import CompareModal from "components/CompareModal/CompareModal";
import TableContainer from "@material-ui/core/TableContainer";
import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import GridItem from "components/Grid/GridItem.js";
import TableRow from "@material-ui/core/TableRow";
import Table from "@material-ui/core/Table";
//redux
import { useDispatch, useSelector } from "react-redux";
import {
  selectProductDetail,
  selectAttributeGroups,
  setCompareProducts,
  rmCompareProducts,
  selectCompareProducts,
} from "redux/features/QuotationForm/quotationResultSlice";
import {selectThemeConfig} from 'redux/features/Theme/ThemeSlice'
//utils
import productStyle from "assets/jss/material-kit-pro-react/views/productStyle.js";
import CustomHeader from "components/CustomHeader/CustomHeader";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: productStyle.infoColor,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const decodeHtml = (html) => {
  var txt = document.createElement("textarea")
  txt.innerHTML = html
  return txt.value
}

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles(productStyle);

export default function ProductPage() {
  const theme = useSelector(selectThemeConfig)
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });
  const dispatch = useDispatch();
  const compareProducts = useSelector(selectCompareProducts);
  const productData = useSelector(selectProductDetail);
  const attGroups = useSelector(selectAttributeGroups);
  const classes = useStyles();

  const [compareModal, setCompareModal] = React.useState(false);
  const [compareState, setCompareState] = React.useState(
    compareProducts.includes(productData[0]) ? false : true
  );


  const handleCompareChange = (value) => {
    if (compareProducts.includes(value)) {
      dispatch(rmCompareProducts(value));
      setCompareState(true);
    } else {
      dispatch(setCompareProducts([...compareProducts, value]));
      setCompareState(false);
    }
  };

  return (
    <React.Fragment>
      <div
        id="ProductPage" 
        className={classNames(classes.main,classes.container, classes.mainRaised)} 
        style={{ margin: "auto", marginTop: "1em", paddingLeft: "0", paddingRight: "0"}}
        >
      {theme.header ? <CustomHeader style={{ margin: "auto", width: "95%"}} asesorInfo={true}/>: null}
        <GridContainer direction="row">
          <GridItem xs={12}>
            <GridContainer style = {{padding: "2em 0"}}>
              <GridItem
                container
                justify="center"
                alignItems="center"
                xs={3}
              >
                <img src={productData[1].thumb} height="100px" />
              </GridItem>
              <GridItem xs={9}>
                <h3 style={{margin: "auto"}} className={classes.title}>{productData[1].name}</h3>
                <h3 style={{margin: "auto"}} className={classes.mainPrice}>
                  {productData[1].price}
                </h3>
                {productData[1].valorCuotaFinanciacion !== "" && (
                  <p>
                    <i>
                      O {productData[1].numeroCuotaFinanciacion} cuotas de{" "}
                      {productData[1].valorCuotaFinanciacion}.
                        </i>
                  </p>
                )}
                <Button
                  style={{backgroundColor: theme.primary}}
                  size="sm"
                  onClick={() => handleCompareChange(productData[0])}
                >
                  {compareState
                    ? "Añadir a comparativa"
                    : "Remover de la comparativa"}
                </Button>
              </GridItem>
            </GridContainer>
            <TableContainer component={Paper} style={{ marginTop: "1rem" }}>
              <Table
                aria-label="customized table"
              >
                <TableBody>
                  {attGroups.map((group) => (
                    group[1].attribute ? <React.Fragment key={group[0]}>
                      <StyledTableRow >
                        <StyledTableCell align="left" colSpan={2} style={{backgroundColor: theme.normalText}}>
                          <h6 style={{ fontSize: "0.8rem" }}>
                            {group ? group[1].name : null}
                          </h6>
                        </StyledTableCell>
                      </StyledTableRow>
                      {Object.keys(group[1].attribute).map((el, i) => (
                        <TableRow key={i}>
                          <StyledTableCell align="left">
                            <b>{group[1].attribute[el].name}</b>
                          </StyledTableCell>
                          <TableCell
                            align={el === "67" ? "left" : "center"}
                          >
                            {el === "67" ? (
                              decodeHtml(productData[1].attribute[el])
                                .replace(/&amp;/g,'&').replace(/&lt;/g, '<').replace(/&gt;/g, '>')
                                .split(/<\/li><\/ul>|<\/li> <\/ul>/)
                                .filter((str) => str.trim().length > 0)
                                .map((str, i) => (
                                  <p
                                    key={i}
                                    className={classes.description}
                                  >
                                    - {str.replace(/<.+?>/g, "")}
                                  </p>
                                ))
                            ) : (
                              <span className={classes.description}>
                                {productData[1].attribute[el] === "" ||
                                  productData[1].attribute[el] === undefined
                                  ? "N/A."
                                  : decodeHtml(productData[1].attribute[el]) + "."}
                              </span>
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                    </React.Fragment> : null
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </GridItem>
        </GridContainer>
        <Typography
          color="textSecondary"
          align="justify"
          className={classes.legalNote}
        >
          Cada cotización es provisional y no implica aceptación del riesgo,
          todas las condiciones incluyendo precios, tasas de financiación y
          coberturas están sujetas a cambios, revisión, verificación y
          aceptación acorde las políticas y parámetros de las aseguradoras e
          intermediario, pudiendo variar el momento de emitir la póliza.
          Tiempo de vigencia de las cotizaciones es de 5 días calendario.
            </Typography>
        <Button
          round
          style={{backgroundColor: theme.primary}}
          className={classes.compareButton}
          onClick={() => setCompareModal(true)}
        >
          <CompareIcon style={{ color: "#FFFFFF" }} />
            Comparar
          </Button>
      </div>
      <CompareModal theme={theme} showModal={compareModal} handleModal={setCompareModal} />
    </React.Fragment>
  );
}
