import { makeStyles, Step, StepLabel, Stepper } from '@material-ui/core';
import { Search } from '@material-ui/icons';
import quotationStyle from 'assets/jss/material-kit-pro-react/views/quotationStyle';
import classNames from 'classnames';
import InfoArea from 'components/InfoArea/InfoArea';
import { PulseLoader } from "react-spinners";
import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router'
import {
    setPersonData,
    setDocumentData,
    setVehicleData,
    setRiskData,
    setPlateNumber
} from 'redux/features/QuotationForm/quotationDataSlice';

import {
    setAsesorInfo,
    getJSON,
    selectQuotationResultJSON,
    selectQuotationResultURL,
    setUrlData
} from 'redux/features/QuotationForm/quotationResultSlice';

import { setTokenData } from 'redux/features/TokenData/tokenDataSlice';
import CustomStepIcon from 'components/CustomStepIcon/CustomStepIcon';
import { selectTokenData } from 'redux/features/TokenData/tokenDataSlice';
import { selectAsesorToken } from 'redux/features/QuotationForm/quotationDataSlice';
import { setTime } from 'redux/features/QuotationForm/quotationDataSlice';

const useStyles = makeStyles(quotationStyle);

export default function useCotization() {

    const asesorToken = useSelector(selectAsesorToken)

    const step = [
        "Datos del vehículo",
        "Número de documento",
        "Confirma tu vehículo",
        "Información del propietario",
    ];

    const classes = useStyles();
    const localStorage = window.localStorage;

    const dispatch = useDispatch();
    const quotationResultUrl = useSelector(selectQuotationResultURL);
    const quotationResultJSON = useSelector(selectQuotationResultJSON);
    const history = useHistory();
    const paramSearch = new URLSearchParams(window.location.search);

    const tokenData = useSelector(selectTokenData)

    let lastQuotation = ''
    
    React.useEffect(()=>{
        if (localStorage.getItem(`lastQuotationUrl-${asesorToken}`) && localStorage.getItem(`lastQuotationUrl-${asesorToken}`).length > 1){
            lastQuotation = localStorage.getItem(`lastQuotationUrl-${asesorToken}`).split('/')[0];
        }
    }, [localStorage])

    React.useEffect(() => {
        const cotizationId = paramSearch.get('cotizacion') || lastQuotation;
        dispatch(setUrlData({
            data: {
                event_id: window.atob(cotizationId).split('-')[1].split('x')[1],

                json_comparativo: `https://app.agentemotor.com/index.php?tmpl=component&option=com_aceshop&route=product/comparejson_v2&opt=all&cotizacion=${cotizationId}`,

                pdf_comparativo: `https://app.agentemotor.com/cotizaciones/consultas/generarPdf.php?url=https%3A%2F%2Fapp.agentemotor.com%2Fcotizaciones-cliente%2Fcompare%3FprimeraVista%3D1%26opt%3Dall%26cotizacion%3D${cotizationId}%3D%3D&name=comparativo`,

                comparativo_url: `https://app.agentemotor.com/cotizaciones-cliente/compare?primeraVista=1&opt=all&cotizacion=${cotizationId}`,

                resultado: "exitoso"

            },
            status: "success"
        }));

    }, []);

    React.useEffect(() => {
        if (quotationResultUrl.data.resultado === "exitoso") {
            dispatch(getJSON(quotationResultUrl.data.json_comparativo));
        }
    }, [quotationResultUrl]);

    React.useEffect(() => {
        if (quotationResultJSON.status === "success") {
            const jsonResponse = quotationResultJSON.data;
            dispatch(setAsesorInfo(jsonResponse.asesor_Info));
            if (tokenData.status != "success"){
                dispatch(setTokenData({
                    data: {
                        cf_logo: jsonResponse.agenciaInfo.cf_logo,
                        first_name: jsonResponse.asesor_Info.first_name,
                        last_name: jsonResponse.asesor_Info.last_name,
                        email1: jsonResponse.asesor_Info.email1,
                        phone_mobile: jsonResponse.asesor_Info.phone_mobile
                    },
                    status: "success"
                }));
            }
            dispatch(setPlateNumber(jsonResponse.vehiculoInfo.cf_veh_placa));
            dispatch(setDocumentData({
                idType: jsonResponse.aseguradoInfo.tipo_identificacion,
                idNumber: jsonResponse.aseguradoInfo.numero_documento
            }));
            const birthdate = jsonResponse.aseguradoInfo.birthday.split('-');
            const date = new Date();
            date.setFullYear(birthdate[0])
            date.setMonth(birthdate[1])
            date.setDate(birthdate[2])
            dispatch(setPersonData({
                name: jsonResponse.aseguradoInfo.firstname,
                surname: jsonResponse.aseguradoInfo.lastname,
                phoneNumber: jsonResponse.aseguradoInfo.phone || "Sin Asignar",
                email: jsonResponse.aseguradoInfo.email || "Sin Asignar",
                idType: jsonResponse.aseguradoInfo.tipo_identificacion,
                idNumber: jsonResponse.aseguradoInfo.numero_documento,
                gender: jsonResponse.aseguradoInfo.genero,
                birthdate: date.toString(),
            }));
            dispatch(setVehicleData({
                code: jsonResponse.vehiculoInfo.cf_veh_fasecolda,
                plate: jsonResponse.vehiculoInfo.cf_veh_placa,
                type: jsonResponse.vehiculoInfo.cf_veh_clase,
                brand: jsonResponse.vehiculoInfo.cf_veh_marca,
                model: jsonResponse.vehiculoInfo.cf_veh_modelo,
                codification: {
                    line1: ""
                }
            }));
            const place = jsonResponse.vehiculoInfo.ciudad_circulacion_veh.split(' - ');
            dispatch(setRiskData({
                vehiclePrice: jsonResponse.vehiculoInfo.cf_veh_precio,
                accesoriesPrice: jsonResponse.vehiculoInfo.cf_veh_valor_accesorios,
                placeData: `${place[1]} ( ${place[2]} )`
            }));
            const now = new Date();
            now.setMinutes(now.getMinutes() + 1)
            dispatch(setTime(now.toString()))
            history.push('/results');          
            
        }
    }, [quotationResultJSON]);

    return (
        <React.Fragment>
            <div className={classNames(classes.main, classes.mainRaised, classes.container)} style={{ margin: "auto", maxWidth: "95%", marginTop: "1em" }}>
                {/* <CustomHeader style={{ width: "95%", margin: "auto" }} asesorInfo={false} /> */}
                {/*carga la sección del formulario - Header deshabilitado hasta poder obtener la info de la agencia*/}
                <Stepper activeStep={4} alternativeLabel>
                    {step.map((label) => (
                        <Step key={label}>
                            <StepLabel StepIconComponent={CustomStepIcon}>
                                <span>{label}</span>
                            </StepLabel>
                        </Step>
                    ))}
                </Stepper>
                <div style={{ alignContent: "center", display: "flex" }}>
                    <div style={{ maxWidth: "30%", margin: "auto", marginTop: "2em" }}>
                        <PulseLoader color="#09f" />
                    </div>
                </div>
                <InfoArea
                    style={{ marginTop: "0" }}
                    title="Consultando"
                    description="Recuperando datos de la cotizacion, sera redirigido en un momento..."
                    icon={Search}
                    iconColor="success"
                />
            </div>
        </React.Fragment>
    )
}