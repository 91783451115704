import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
import { useHistory } from 'react-router-dom'
// @material-ui/core components
import { withStyles, makeStyles } from "@material-ui/core/styles";
import TableContainer from "@material-ui/core/TableContainer";
import Typography from "@material-ui/core/Typography";
import {css} from '@emotion/react'
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Table from "@material-ui/core/Table";
import Paper from "@material-ui/core/Paper";
// @material-ui/icons
// core components
//redux
import { useSelector } from "react-redux";
import {
  selectAttributeGroups,
  selectTotalProducts,
  selectCompareProducts,
} from "redux/features/QuotationForm/quotationResultSlice";
//utils
import productStyle from "assets/jss/material-kit-pro-react/views/productStyle.js";
import CustomHeader from "components/CustomHeader/CustomHeader";
import {selectThemeConfig} from 'redux/features/Theme/ThemeSlice'

const overlay = css`
  overflow-y: overlay;
`

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const insThumb = {
  display: "block",
  margin: "0 auto",
  height: "80px",
  borderRadius: "0.5rem",
}

const useStyles = makeStyles(productStyle);

export default function ProductPage() {

  const decodeHtml = (html) => {
    var txt = document.createElement("textarea")
    txt.innerHTML = html
    return txt.value
  }

  const theme = useSelector(selectThemeConfig);
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });
  const history = useHistory()

  const goBack = () => {
    history.goBack()
  }

  const attGroups = useSelector(selectAttributeGroups);
  const totalProducts = useSelector(selectTotalProducts);
  const compareProducts = useSelector(selectCompareProducts);

  const productList = totalProducts.filter((prod) =>
    compareProducts.includes(prod[0])
  );

  const classes = useStyles();

  return (
    <div
      id="ComparePage"
      className={classNames(classes.main, classes.container, classes.mainRaised)}
      style={{ margin: "auto", paddingLeft: "0", paddingRight: "0", maxWidth: "100%", overflowX: "hidden"}}
    >
      {theme.header ? <CustomHeader style={{ margin: "auto" }} asesorInfo={true} /> : null}
      <TableContainer component={Paper} className={classNames(classes.table, overlay)} style={{ width: "102%", margin: "auto"}}>
        <Table stickyHeader aria-label="customized table">
          <TableHead>
            <TableRow style={{backgroundColor: theme.tableHead}}>
              <TableCell 
                className={classes.styledTableCell} 
                style={{position: "sticky", color: theme.darkText, left: "0", backgroundColor: theme.tableHead, zIndex: "2", width: "10%"}}
              >
                  CARACTERÍSTICAS
              </TableCell>
              {productList.map((prod) => (
                <TableCell key={prod[0]} style={{zIndex: "1", backgroundColor: theme.tableHead}} align="center" className={classes.styledTableCell}>
                  <img src={prod[1].thumb} style={insThumb} />
                  <h6 style={{ fontWeight: "bolder", color: "#000", fontSize: "1.2em"}}>{prod[1].price}</h6>
                  <h6 style={{ fontWeight: "bold", color: theme.darkText}}>{prod[1].name}</h6>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {attGroups.map((group) => (
              group[1].attribute ? <React.Fragment>
                <StyledTableRow key={group[0]} style={{backgroundColor: theme.normalText, zIndex: "0"}}>
                  <TableCell
                    align="left"
                    colSpan = {2}
                    style={{position: "sticky", left: "0"}}
                  >
                    <h6 style={{ fontSize: "1rem"}}>{group[1].name}</h6>
                  </TableCell>
                  {
                    productList.length > 1 ?
                    <TableCell
                    align="left"
                    colSpan = {productList.length - 1}
                  >
                  </TableCell>
                    : ""
                  }
                </StyledTableRow>
                {Object.keys(group[1].attribute).map((el, i) => (
                  <TableRow key={i}>
                    <TableCell align="left" style={{position: "sticky", left: "0", backgroundColor:"white"}}>
                      <b>{group[1].attribute[el].name}</b>
                    </TableCell>
                    {productList.map((prod, i) => (
                      <TableCell align={el === "67" ? "left" : "center"} justifyContent="center">
                        {el === "67" ? (
                         decodeHtml( prod[1].attribute[el])
                            .replace(/&lt;/g, '<').replace(/&gt;/g, '>')
                            .split(/<\/li><\/ul>|<\/li> <\/ul>/)
                            .filter((str) => str.trim().length > 0)
                            .map((str, i) => (
                              <p key={i} className={classes.description}>
                                - {str.replace(/<.+?>/g, "")}
                              </p>
                            ))
                        ) : (
                          <span className={classes.description}>
                            {prod[1].attribute[el] === "" ||
                              prod[1].attribute[el] === undefined
                              ? "N/A."
                              : decodeHtml(prod[1].attribute[el]) + "."}
                          </span>
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </React.Fragment> : null
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Typography color="textSecondary" className={classes.legalNote}>
        Cada cotización es provisional y no implica aceptación del riesgo,
        todas las condiciones incluyendo precios, tasas de financiación y
        coberturas están sujetas a cambios, revisión, verificación y
        aceptación acorde las políticas y parámetros de las aseguradoras e
        intermediario, pudiendo variar el momento de emitir la póliza.
        Tiempo de vigencia de las cotizaciones es de 5 días calendario.
            </Typography>
    </div>
  );
}
