import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const initialState = {
    primary: "#2084E3",
    secundary: "#F1F3F4",
    barColor: "#F1F3F4",
    darkText: "#3C4858",
    normalText: "#D9DBDB",
    tableHead: "#EAEAEA",
    header: true,
}

const themeSlice = createSlice({
    name: "themeConfig",
    initialState,
    reducers: {
      resetThemeConfigState: (state) => initialState,
      setThemeConfig: (state, action) => {
        state = action.payload
      },
      setHeader: (state, action) => {
        state.header = action.payload;
      }
    }
})

export const {resetThemeConfigState, setThemeConfig, setHeader} = themeSlice.actions;

export const selectThemeConfig = (state) => state.themeConfig
export const selectHeaderConfig = (state) => state.themeConfig.header 

export default themeSlice.reducer