//carga de información recolectada en el formulario al estado global

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  searchType: "plate",
  documentData: {
    idType: "CC",
    idNumber: "",
  },
  plateNumber: "",
  personData: "",
  companyData: "",
  vehicleData: "",
  placeData: "",
  riskData: "",
  asesorToken: "",
  sortType: "",
  asesor: "",
  utmParams: "",
  time: "",
  isQuote: false,
  beneficiario: false
};

const quotationDataSlice = createSlice({
  name: "quotationData",
  initialState,
  reducers: {
    setTime: (state, action) => {
      state.time = action.payload;
    },
    setUtmParams: (state, action) => {
      state.utmParams = action.payload;
    },
    setSortType: (state, action) => {
      state.sortType = action.payload;
    },
    setAsesorData: (state, action) => {
      state.asesor = action.payload;
    },
    setSearchType: (state, action) => {
      state.searchType = action.payload;
    },
    setAsesorToken: (state, action) => {
      state.asesorToken = action.payload;
    },
    setPlateNumber: (state, action) => {
      state.plateNumber = action.payload.toUpperCase();
    },
    setDocumentData: (state, action) => {
      state.documentData.idType = action.payload.idType;
      state.documentData.idNumber = action.payload.idNumber;
    },
    setVehicleData: (state, action) => {
      state.vehicleData = action.payload;
    },
    setPlaceData: (state, action) => {
      state.placeData = action.payload;
    },
    setRiskData: (state, action) => {
      state.riskData = action.payload;
    },
    setPersonData: (state, action) => {
      state.personData = action.payload;
    },
    setCompanyData: (state, action) => {
      state.companyData = action.payload;
    },
    setReferenceData: (state, action) => {
      state.vehicleData = { ...state.vehicleData, plate: action.payload.plate };
    },
    setIsQuote: (state, action) => {
      state.isQuote = action.payload;
    },
    setBeneficiario: (state, action) => {
      state.beneficiario = action.payload
    }
  },
});

export const {
  setDocumentData,
  setAsesorToken,
  setSearchType,
  setPlateNumber,
  setVehicleData,
  setPlaceData,
  setRiskData,
  setPersonData,
  setCompanyData,
  setReferenceData,
  setSortType,
  setAsesorData,
  setUtmParams,
  setTime,
  setIsQuote,
  setBeneficiario
} = quotationDataSlice.actions;

export const selectQuotationData = (state) => state.quotationData;

export const selectDocumentData = (state) => state.quotationData.documentData;
export const selectPlateNumber = (state) => state.quotationData.plateNumber;
export const selectCompanyData = (state) => state.quotationData.companyData;
export const selectContactData = (state) => state.quotationData.contactData;
export const selectVehicleData = (state) => state.quotationData.vehicleData;
export const selectPersonData = (state) => state.quotationData.personData;
export const selectSearchType = (state) => state.quotationData.searchType;
export const selectPlaceData = (state) => state.quotationData.placeData;
export const selectRiskData = (state) => state.quotationData.riskData;
export const selectAsesorToken = (state) => state.quotationData.asesorToken;
export const selectSortType = (state) => state.quotationData.sortType;
export const selectAsesorData = (state) => state.quotationData.asesor;
export const selectUtmParams = (state) => state.quotationData.utmParams;
export const selectTime = (state) => state.quotationData.time;
export const selectIsQuote = (state) => state.quotationData.isQuote;
export const selectBeneficiario = (state) => state.quotationData.beneficiario;

export default quotationDataSlice.reducer;
