import React from "react";

import { useSelector } from "react-redux";
//core components
import LoadingData from "views/QuotationPage/Sections/Stepper/Alerts/LoadingData";
import ErrorOnLoad from "views/QuotationPage/Sections/Stepper/Alerts/ErrorOnLoad";
import VehicleDetailCard from "components/VehicleDetailCard/VehicleDetailCard";
import CustomButton from "components/CustomButtons/Button";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
//redux
import { selectVehicleByReferenceData } from "redux/features/QuotationForm/vehicleByReferenceSlice";
import { selectVehicleByPlateData } from "redux/features/QuotationForm/vehicleByPlateSlice";
import { selectSearchType } from "redux/features/QuotationForm/quotationDataSlice";
import SearchResultModal from "components/SearchResultModal/SearchResultModal";


const Step3 = (props) => {
  const theme = props.theme;
  /*carga información sobre tipo de búsqueda */
  const searchType = useSelector(selectSearchType);
  /*carga información recuperada a través de la API (Busqueda por placa)*/
  const vehicleByPlateSelector = useSelector(selectVehicleByPlateData);
  /*carga información recuperada a través de la API (Busqueda por referencia)*/
  const vehicleByReferenceSelector = useSelector(selectVehicleByReferenceData);
  const [vehicleData, setVehicleData] = React.useState({})
  const [reqStatus, setReqStatus] = React.useState("")

  const [searchModal, setSearchModal] = React.useState(false);

  React.useEffect(() => {
    /*asigna los datos de la petición dependiendo del tipo de consulta*/
    searchType === "plate"
      ? (setVehicleData(vehicleByPlateSelector.data))
      : (setVehicleData(vehicleByReferenceSelector.data));
    /*asigna el estado de la petición dependiento del tipo de consulta */
    searchType === "plate"
      ? (setReqStatus(vehicleByPlateSelector.status))
      : (setReqStatus(vehicleByReferenceSelector.status));
  }, [vehicleByPlateSelector, vehicleByReferenceSelector])

  React.useEffect(() => {
    if (reqStatus === "failed" && searchType === "plate"){
      console.log(reqStatus);
      setSearchModal(true)
    }
  }, [reqStatus])

  return (
    <GridContainer justify="center" spacing={2}>
      <GridItem xs={12} style={{textAlign: "center"}}>
        <h2
          style={{ textAlign: "center" }}
          onClick={() => console.log(searchType)}
        >
          Confirma tu vehículo
        </h2>
      </GridItem>
      <GridItem container justify="center" xs={12} style={{ display: reqStatus === "loading" ? "block" : "flex" }}>
        {/*esperando respuesta de la API*/}
        {reqStatus === "loading" && <LoadingData />}
        {/*respuesta obtenida */}
        {(reqStatus === "success" && vehicleData.length >= 1) &&
          vehicleData.map((el, i) => (
            <GridItem container justify="center" xs={12} md={6} xl={4} key={i}>
              <VehicleDetailCard
                theme={theme}
                dataLength={vehicleData.length}
                data={el.vehicle}
                handleStep={props.handleStep}
              />
            </GridItem>
          ))}
        {/*error en la respuesta de la API */}
        {reqStatus === "failed" && <ErrorOnLoad />}
      </GridItem>
      <GridItem container justify="center" xs={12} sm={4}>
        <CustomButton
          onClick={() => props.handleBack()}
          fullWidth
          simple
          style={{ color: theme.primary }}
        >
          Atrás
        </CustomButton>
      </GridItem>
      {
        reqStatus == "success" && vehicleData.length == 0 ?
          <SearchResultModal
            theme={theme}
            showModal={true}
            message={true}
            handleModal={setSearchModal}
            handleStep={props.handleStep}
          /> : ""
      }
      <SearchResultModal
        theme={theme}
        showModal={searchModal}
        handleModal={setSearchModal}
        handleStep={props.handleStep}
      />
    </GridContainer>
  );
};

export default Step3;
