import React, { useEffect } from 'react'
import { useSelector } from "react-redux";

//Redux
import { selectAsesorInfo, selectTotalProducts } from 'redux/features/QuotationForm/quotationResultSlice'
import { getTokenData, selectTokenData } from "redux/features/TokenData/tokenDataSlice";

//Material Ui
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import { ArrowBack, Phone, WhatsApp } from "@material-ui/icons";
import Button from "components/CustomButtons/Button.js";
import styles from "assets/jss/material-kit-pro-react/views/ecommerceSections/latestOffersStyle.js";
import { makeStyles } from "@material-ui/core/styles";
import profilePageStyle from 'assets/jss/material-kit-pro-react/views/profilePageStyle';
import { useHistory } from 'react-router';
import { motion } from 'framer-motion';
import {selectThemeConfig} from 'redux/features/Theme/ThemeSlice';

const ClassCustom = {
    hiddeXS: {
        "@media (max-width: 576px)": {
            display: "none",
        }
    },
    showXS: {
        "@media (min-width: 576px)": {
            display: "none",
        }
    }
}

const customClass = makeStyles(ClassCustom);

const isFull = /^https?:\/\/[\w\-]+(\.[\w\-]+)+[/#?]?.*$/

const useStyles = makeStyles(styles);

//const maria = "https://mariaeseguros.com/wp-content/uploads/2017/09/logo.png"

export default function CustomHeader({ asesorInfo = true, backButton = true }) {

    const theme = useSelector(selectThemeConfig);
    const [asesor, setAsesor] = React.useState({})

    const isPhone = /^[2-9]{1}[0-9]{9}$/

    const history = useHistory();

    const cClass = customClass();

    const goBack = () => {
        history.goBack()
    }

    const tokenData = useSelector(selectTokenData)
    const [loading, setLoading] = React.useState(true)

    React.useEffect(() => {
        if (tokenData.status === "success") {
            setAsesor(tokenData.data);
            setLoading(false)
        }
    }, [tokenData])

    const classes = useStyles()

    return (
        <div style={{ backgroundColor: theme.secundary }}>
            <GridContainer direction="row">
                {backButton ?
                    <GridItem xs={2} md={1} style={{ display: "flex", alignItems: "center" }}>
                        <div style={{ textAlign: "center", alignContent: "center", margin: "auto" }}>
                            <motion.span
                                onClick={goBack}
                                style={{ display: "block" }}
                                id="go_back"
                                whileHover={{ scale: 1.1, cursor: 'pointer' }}
                            >
                                <Button justIcon round style={{backgroundColor: theme.primary}}><ArrowBack style={{ color: "#FFFFFF" }} /></Button>
                            </motion.span>
                            <span
                                className={cClass.hiddeXS}
                                htmlFor="go_back"
                                style={{ fontSize: "1em", cursor: "pointer", fontWeight: "bold" }}>
                                Atrás</span>
                        </div>
                    </GridItem> : ""}
                <GridItem xs={3} md={2}
                    style={{ padding: "1em 0", display: "flex", alignItems: "center" }}
                >
                    <img
                        src={isFull.test(asesor.cf_logo) ? asesor.cf_logo : `https://app.agentemotor.com/${asesor.cf_logo}`}
                        style={{ maxWidth: "60%", margin: "auto", display: "block", maxHeight: "100px" }}
                    />
                </GridItem>
                <GridItem xs={backButton ? 7 : 9} md={backButton ? 5 : 6}
                    style={{ padding: "1em 0", paddingLeft: "1em", display: "flex", alignItems: "center", wordBreak: "break-word" }}
                >
                    <span style={{ fontWeight: "bold", color: theme.darkText}} className={cClass.hiddeXS}>
                        { loading ? "Recolectando informacion del asesor..."
                            : (`Tu asesor es ${asesor.first_name} ${asesor.first_name != asesor.last_name ? asesor.last_name : ""} - ${asesor.email1} ${isPhone.test(asesor.phone_mobile) ? "- Cel:" + asesor.phone_mobile: ""}`)}
                    </span>
                    <span className={cClass.showXS} style={{ fontWeight: "bold", overflowWrap: "break-word", color:theme.darkText}}>
                        {loading ? "Recolectando informacion del asesor..." : (`${asesor.first_name} ${asesor.first_name != asesor.last_name ? asesor.last_name : ""}`) } <br />
                        {loading ? "" : (isPhone.test(asesor.phone_mobile) ? <React.Fragment>{asesor.phone_mobile} <br /></React.Fragment> : "")} 
                        {loading ? "" : `${asesor.email1}`}
                    </span>
                </GridItem>
                {isPhone.test(asesor.phone_mobile)
                    ? ( loading ? "" : <GridItem xs={12} md={4}
                    style={{ textAlign: "center", padding: "1em 0", display: "flex", alignItems: "center" }}>
                    <Button
                        className={classes.navLink}
                        style={{ margin: "auto" }}
                        onClick={(e) => {
                            e.preventDefault()
                            window.open(`https://api.whatsapp.com/send?phone=57${asesor.phone_mobile}`, "_blank");
                        }}
                        color="success"
                        round
                        startIcon={<WhatsApp />}
                    >
                        WhatsApp
                </Button>
                    <Button
                        href="#pablo"
                        style={{ margin: "auto", backgroundColor: theme.primary }}
                        className={classes.navLink}
                        onClick={(e) => {
                            e.preventDefault();
                            window.open(`tel:+57${asesor.phone_mobile}`, "_blank");
                        }}
                        round
                        startIcon={<Phone />}
                    >
                        Llamar
          </Button>
                </GridItem>) : ""
                }
            </GridContainer>
        </div>
    )
}