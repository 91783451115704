/*eslint-disable*/
import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
// core components
// sections for this page
import SectionForm from "./Sections/SectionForm";
//estilos
import quotationStyle from "assets/jss/material-kit-pro-react/views/quotationStyle.js";
import {selectThemeConfig} from 'redux/features/Theme/ThemeSlice';
import { useSelector } from "react-redux";
import CustomHeader from "components/CustomHeader/CustomHeader";

const useStyles = makeStyles(quotationStyle);

//landing/home de la app. 

export default function AboutUsPage(props) {

  const theme = useSelector(selectThemeConfig);

  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });

  const classes = useStyles();
  return (
    <div>
      <div className={classNames(classes.main, classes.mainRaised, classes.container)} style={{ margin: "auto", marginTop: "1em", paddingLeft: "0", paddingRight: "0"}}>
        {theme.header ? <CustomHeader style={{ width: "95%", margin: "auto" }} asesorInfo={false} backButton={false}/> : null}
        {/*carga la sección del formulario - Header deshabilitado hasta poder obtener la info de la agencia*/}
        <SectionForm theme={theme}/>
      </div>
    </div>
  );
}
