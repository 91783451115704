import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
// redux
import { selectQuotationResultJSON } from "redux/features/QuotationForm/quotationResultSlice";
import { selectQuotationResultURL } from "redux/features/QuotationForm/quotationResultSlice";
import {
  selectQuotationData,
  selectAsesorToken,
} from "redux/features/QuotationForm/quotationDataSlice";
import { getQuotation } from "redux/features/QuotationForm/quotationResultSlice";
import { getJSON } from "redux/features/QuotationForm/quotationResultSlice";
import { CastPersonData } from "utils/functions";
import { setTokenData } from "redux/features/TokenData/tokenDataSlice";
import { makeStyles } from "@material-ui/core/styles";
import { selectTokenData } from "redux/features/TokenData/tokenDataSlice";
import { setTime } from "redux/features/QuotationForm/quotationDataSlice";
import styles from "assets/jss/material-kit-pro-react/components/infoStyle.js";
import { setIsQuote } from "redux/features/QuotationForm/quotationDataSlice";
import { selectPersonData } from "redux/features/QuotationForm/quotationDataSlice";

const useStyles = makeStyles(styles);

const FinalStep = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  //recupera la información de la cotización recolectada en los formularios
  const quotationData = useSelector(selectQuotationData);
  const asesorToken = useSelector(selectAsesorToken);
  //console.log(CastPersonData(quotationData))
  const quotationResultURL = useSelector(selectQuotationResultURL);
  const quotationResultJSON = useSelector(selectQuotationResultJSON);
  const localStorage = window.localStorage;

  const [temp, setTemp] = React.useState(45)
  const [done, setDone] = React.useState(false)
  
  const tokenData = useSelector(selectTokenData)
  const {email} = useSelector(selectPersonData)

  React.useEffect(() => {
    let timer;
    if(temp > 0){
      timer = setTimeout(() => setTemp(temp - 1), 1000);
    }
    temp === 0 && setTemp(15)
    return () => {
      clearTimeout(timer)
    }
  }, [temp]);


  React.useEffect(() => {
    //console.log(CastPersonData(quotationData));
    dispatch(getQuotation({
      data: CastPersonData(quotationData), 
      token: asesorToken,
    }));
  }, []);

  React.useEffect(() => {
    quotationResultURL.data.resultado === "exitoso" &&
      dispatch(getJSON(quotationResultURL.data.json_comparativo));
  }, [quotationResultURL]);

  React.useEffect(() => {
    if (quotationResultJSON.status === "success") {
      const jsonResponse = quotationResultJSON.data;
      if (tokenData.status != "success") {
        dispatch(setTokenData({
          data: quotationResultJSON.data.agenciaInfo,
          status: "success"
        }));
      }
      let searchParams = new URLSearchParams(quotationResultURL.data.json_comparativo);
      localStorage.clear();
      localStorage.setItem(`lastQuotationUrl-${asesorToken}`, `${searchParams.get('cotizacion')}/${jsonResponse.vehiculoInfo.cf_veh_marca}/${jsonResponse.vehiculoInfo.cf_veh_placa}/${jsonResponse.vehiculoInfo.cf_veh_referencia.split(/ \[\d\] /)[0]}`);    
      const now = new Date();
      now.setMinutes(now.getMinutes() + 2)
      dispatch(setTime(now.toString()))
      dispatch(setIsQuote(true))
      history.push("/results")
    }
  }, [quotationResultJSON]);

  //pantalla de espera de respuesta de la API una vez se ha ejecutado la consulta de la cotización
  return (
    <div style={{display: "block", padding: "2em 0"}} id="FinalStep">
        <div style={{alignContent: "center", display: "flex"}}>
            <div style={{maxWidth: "30%", margin: "auto", marginTop: "2em"}}>
                {/* <PulseLoader color="#09f"/> */}
                <span style={{color: '#2084E3', fontSize: "1.6em", fontWeight: "bold" }}>{temp}</span> 
            </div>
        </div>
        <h4 style={{
          color: "#3C4858",
          margin: "auto",
          textAlign: "center",
          maxWidth: "80%",
          marginTop: "30px",
          fontWeight: 700,
          marginBottom: 25,
          textDecoration: "none",
        }}>
          Gracias. Ya estamos consultando en este momento con las aseguradoras para mostrarte las mejores ofertas.
        </h4>
        <h6 style={{
        textAlign: "center",
        margin: "auto",
        maxWidth: "80%",
        fontSize: "0.85rem",
        textTransform: "none",
        color: "#2084e3"
        }}>
          Enviaremos la cotización de seguro a {email} en unos 15 minutos.
          Por favor, si no lo encuentras en tú buzón, revisar en la carpeta de spam
        </h6>
    </div>
  );
};

export default FinalStep;
