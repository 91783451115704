//consulta de información de la aseguradora a través de token.
//implementada para la carga de la información mostrada en header (logo e info de la compañía)

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const getTokenData = createAsyncThunk(
  "insurances/getTokenDataStatus",
  async (token) => {
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append(
      "Cookie",
      //token 'quemado' para testeo.
      "XSRF-TOKEN=eyJpdiI6IjNPaWRDSUZVeDdpSzJIVzdhVDR1N2c9PSIsInZhbHVlIjoiSmNyUHNKWUJvZWEyN1hsRWhUQWZYTTcxbmtSVVVMbXdyYXg1Ulp1bG9NaW8rS2xkMkpOTHhHVmRVVnBxaVdrWiIsIm1hYyI6IjZjMDU5YmM4ZWM3ODgwYjg0MTZmZmY2NTExNjhhY2IwMTg0M2I1OTEyMDU2Nzg4NTliNDAyZGEwZWZlMDgxZGEifQ%3D%3D; agentemotor_session=eyJpdiI6ImZcL0MwVkxDK3kxOUtxTWZxdU1iWENnPT0iLCJ2YWx1ZSI6IlBJWGpwYVdzUXYrTER0RG1PbkN3VEk1XC9cLzJ1SFJnUWQrS2t0T3pcL0FhZjBzNTd3NUh0RDJlZ3lLa0o1K284VGkiLCJtYWMiOiJkOWNlMjQzY2UzYWFlOGM0ZTkyZjAzYjk2MjgwOTZkMjIzZWVhZjI5NmU0YThiM2M4NTdlZjU2ZjRjYTQ2MzhhIn0%3D"
    );

    var data = {
      name: "event-get-data-broker",
      origin: "api.consume",
      token: token.token,
    }

    if (token.asesor){
      data.asesor = token.asesor
    }

    var raw = JSON.stringify(data);

    var requestOptions = {
      method: "POST",
      headers,
      body: raw,
      redirect: "follow",
    };

    return await fetch(
      "https://mi.agentemotor.com/insurances/process/create#event-get-data-broker",
      requestOptions
    ).then((response) => response.json());
  }
);

const initialState = {
  data: [],
  status: "",
};

const tokenDataSlice = createSlice({
  name: "tokenData",
  initialState,
  reducers: {
    resetTokenDataState: (state) => initialState,
    setTokenData: (state, action) => {
      state.data = action.payload.data
      state.status = action.payload.status
    }
  },
  extraReducers: {
    [getTokenData.pending]: (state) => {
      state.status = "loading";
    },
    [getTokenData.fulfilled]: (state, action) => {
      const data = {
        cf_logo: action.payload.cf_logo,
        first_name: action.payload.user_assigned_full_name,
        last_name: "",
        email1: action.payload.email1,
        phone_mobile: action.payload.user_assigned_phone_mobile?.replace('-', '')
      }
      state.data = data;
      state.status = "success";
    },
    [getTokenData.rejected]: (state) => {
      state.status = "failed";
    },
  },
});

export const { resetTokenDataState, setTokenData } = tokenDataSlice.actions;

export const selectTokenData = (state) => state.tokenData;

export default tokenDataSlice.reducer;
