//generación de cotización

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import pRetry from "p-retry";

export const getQuotation = createAsyncThunk(
  "vehicle/getQuotationStatus",
  async (quoteData, asesorToken) => {
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("token", quoteData.token);

    var raw = JSON.stringify(quoteData.data);

    var requestOptions = {
      method: "POST",
      headers,
      body: raw,
      redirect: "follow",
    };

    //"https://pacific-dusk-24048.herokuapp.com/insurances/process/create"

    async function fetchNet() {
      return await fetch(
        "https://mi.agentemotor.com/insurances/process/create",
        requestOptions
      ).then((response) => response.json());
    }

    return await pRetry(fetchNet, {retries: 3})
  }
);

//carga del objeto JSON devuelto por la API una vez se generan las URL
export const getJSON = createAsyncThunk(
  "vehicle/getJSONStatus",
  async (jsonUrl) => {
    const headers = new Headers();

    var requestOptions = {
      method: "GET",
      headers,
      redirect: "follow",
    };

    return await fetch(jsonUrl, requestOptions).then((response) =>
      response.json()
    );
  }
);

const initialState = {
  urlData: {
    data: "",
    status: "",
  },
  quotationJSON: {
    data: "",
    status: "",
  },
  productDetail: {},
  attributeGroups: [],
  totalProducts: [],
  compareProducts: [],
  asesorInfo: {},
};

const quotationResultSlice = createSlice({
  name: "quotationResult",
  initialState,
  reducers: {
    resetQuotationResultState: (state) => initialState,
    setAsesorInfo: (state, action) => {
      state.asesorInfo = action.payload
    },
    setUrlData: (state, action) => {
      state.urlData = action.payload
    },
    setProductDetail: (state, action) => {
      state.productDetail = action.payload;
    },
    setAttributeGroups: (state, action) => {
      state.attributeGroups = action.payload;
    },
    setTotalProducts: (state, action) => {
      state.totalProducts = action.payload;
    },
    setCompareProducts: (state, action) => {
      state.compareProducts = action.payload;
    },
    rmCompareProducts: (state, action) => {
      state.compareProducts = state.compareProducts.filter((val) => val !== action.payload);
    }
  },
  extraReducers: {
    [getQuotation.pending]: (state) => {
      state.urlData.status = "loading";
    },
    [getQuotation.fulfilled]: (state, action) => {
      state.urlData.data = action.payload;
      state.urlData.status = "success";
    },
    [getQuotation.rejected]: (state) => {
      state.urlData.status = "failed";
    },
    [getJSON.pending]: (state) => {
      state.quotationJSON.status = "loading";
    },
    [getJSON.fulfilled]: (state, action) => {
      state.quotationJSON.data = action.payload;
      state.quotationJSON.status = "success";
    },
    [getJSON.rejected]: (state) => {
      state.quotationJSON.status = "failed";
    },
  },
});

export const { resetQuotationResultState,setUrlData, setProductDetail, setAttributeGroups, setTotalProducts, setCompareProducts, rmCompareProducts, setAsesorInfo} = quotationResultSlice.actions;

export const selectQuotationResultURL = (state) =>
  state.quotationResult.urlData;
export const selectQuotationResultJSON = (state) =>
  state.quotationResult.quotationJSON;
export const selectProductDetail = (state) =>
  state.quotationResult.productDetail;
export const selectAttributeGroups = (state) =>
  state.quotationResult.attributeGroups;
export const selectTotalProducts = (state) =>
  state.quotationResult.totalProducts;
export const selectCompareProducts = (state) => 
  state.quotationResult.compareProducts;
export const selectAsesorInfo = (state) =>
  state.quotationResult.asesorInfo

export default quotationResultSlice.reducer;
