import React, { useEffect } from "react";

import { useDispatch} from "react-redux";


import { Route, Switch, useHistory} from "react-router-dom";


import QuotationPage from "views/QuotationPage/QuotationPage";
import ResultsPage from "views/EcommercePage/EcommercePage";
import ProductPage from "views/ProductPage/ProductPage";
import ComparePage from "views/ComparePage/ComparePage";
import ErrorPage from "views/ErrorPage/ErrorPage";
import { setAsesorToken } from "redux/features/QuotationForm/quotationDataSlice";

import useCotization from "views/EcommercePage/useCotization";
import PlantillaEmail from "views/EcommercePage/PlantillaEmail";
import { getTokenData } from "redux/features/TokenData/tokenDataSlice";
import { setAsesorData } from "redux/features/QuotationForm/quotationDataSlice";
import { setUtmParams } from "redux/features/QuotationForm/quotationDataSlice";
import { setBeneficiario } from "redux/features/QuotationForm/quotationDataSlice";
import { setHeader } from "redux/features/Theme/ThemeSlice";


const Routing = ({ token, header, ...rest }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const searchParams = new URLSearchParams(window.location.search);

  //Añadimos el token al estado correspondiente a la cotizacion, para usarlo en la peticion
  
  useEffect(()=>{
    window.history.pushState(null, null, window.location.href);
    window.onpopstate = function (event) {
      //window.history.go(1);
      var option = window.confirm("Si vuelve atras perdera el avance registrado ¿Esta seguro de que quiere hacerlo?");
      if (option == true){
        window.history.back()
      } 
    };
  })

  const manageUTM = () => {
    const isUTM = /(utm_)\w+/
    let utmParams = ''
    for (let param of searchParams){
      if (isUTM.test(param[0])){
        if (utmParams == ''){
          utmParams = `${param[0]}=${param[1]}`
        } else {
          utmParams = utmParams+`&${param[0]}=${param[1]}`
        }
      }
    }

    dispatch(setUtmParams(utmParams))
  }

  const manageParamCode = () => {
    dispatch(setAsesorToken(searchParams.get("paramCode")));
    if (searchParams.get('asesor')){
      dispatch(setAsesorData(searchParams.get('asesor')))
      dispatch(getTokenData({
        token: searchParams.get("paramCode"),
        asesor: searchParams.get("asesor")
      }))
    } else {
      dispatch(getTokenData({
        token: searchParams.get("paramCode")
      }))
    }
    history.push('/')
  }

  const manageBeneficiario = () => {
    if (searchParams.get("beneficiario")){
      dispatch(setBeneficiario(searchParams.get("beneficiario")))
    }
  }

  const manageHeader = () => {
    if (searchParams.get('header')){
      dispatch(setHeader(searchParams.get("header") === "hide" ? false : true))
    }
    if (!header) {
      dispatch(setHeader(false))
    }
  }

  const manageToken = () => {
    const searchToken = new URLSearchParams(token)
    if (searchToken.get('header')){
      dispatch(setHeader(searchToken.get("header") === "hide" ? false : true))
    }
    dispatch(setAsesorToken(searchToken.get('paramCode')));
    if (searchToken.get('asesor')){
      dispatch(setAsesorData(searchToken.get('asesor')))
      dispatch(getTokenData({
        token: searchToken.get("paramCode"),
        asesor: searchToken.get("asesor")
      }))
    } else {
      dispatch(getTokenData({
        token: searchToken.get('paramCode')
      }))
    }
    history.push('/')
  }

  useEffect(() => {
    manageHeader();
    manageBeneficiario();
    manageUTM();
    if (searchParams.get('cotizacion')){
        history.push('/cotization')
    } else if (searchParams.get('paramCode')){
        manageParamCode();
    } else if (token){
        manageToken()
    } else {
      history.push('/404')
      }
    }, []);

  return (
    <React.Fragment>
      <Switch>
        <Route exact path="/" component={QuotationPage} />
        <Route exact path='/cotization' component={useCotization} />
        <Route exact path="/home" component={QuotationPage} />
        <Route exact path="/results" component={ResultsPage} />
        <Route exact path="/productDetail" component={ProductPage} />
        <Route exact path="/compareTable" component={ComparePage} />
        <Route exact path='/email' component={PlantillaEmail} />
        <Route path="*" component={ErrorPage} />
      </Switch>
    </React.Fragment>
  );
};

export default Routing;
